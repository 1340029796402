<template>
  <div v-if="isLoading">
    <div class="bg-overlay" />
    <div class="container">
      <div class="quiz-wrapper">
        <VueIcon
          @click="closeQuizPopup"
          :iconName="getIcon.cross.name"
          :width="getIcon.cross.width"
          :height="getIcon.cross.height"
          class="popup-close"
          iconColor="#000"
        />
        <div class="quiz">
          <div class="question">
            {{ currentQuestion.question }}
            <span>{{ stepCount() }}</span>
          </div>
          <VueListView dir="column" class="option-list" v-if="currentQuestion.options">
            <VueButton
              class="option"
              v-for="o in currentQuestion.options"
              :key="o.optionId"
              :isSingleLine="false"
              :class="optionClass(o.optionId)"
              @click.prevent="() => selectOption(o.optionId)"
              outlined
              >{{ o.option }}</VueButton
            >
          </VueListView>
        </div>
        <BrandButton
          class="btn-answer"
          type="submit"
          :disabled="!selectedAnswer"
          @click="nextQuestion"
        >
          {{ buttonLabel }}
        </BrandButton>
      </div>
    </div>
  </div>
</template>

<script>
import { User } from '@/services/Api/index';
import { useStore } from '@/utils/storeUtils';
import { Rosettes } from '@/services/Api/index';
import { reactive, ref, computed, onMounted } from 'vue';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import { ICON_VARIABLES } from '@/constants/component.constants';
import VueButton from '@/components/shared/VueButton/VueButton.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import VueListView from '@/components/shared/VueListView/VueListView.vue';

const BUTTON_LABELS = {
  ANSWER: 'CEVAPLA',
  NEXT: 'SIRADAKİ SORUYA GEÇ',
  FINISH: 'BİTİR',
};

export default {
  components: { VueIcon, VueButton, VueListView, BrandButton },
  props: {
    rosetteInput: {
      type: Object,
    },
    rosetteType: { type: Number },
  },
  setup(props, { emit }) {
    const survey = reactive({});
    const currentQuestionIndex = ref(0);
    const submitedAnswer = ref(false);
    const selectedAnswer = ref(null);
    const answerResponse = ref(null);
    const isLoading = ref(false);
    const quizStatusType = ref(false);

    const store = useStore();

    const optionClass = id => {
      let optClass = '';
      if (!submitedAnswer.value) {
        optClass = selectedAnswer.value === id ? 'active' : '';
      } else {
        if (answerResponse.value && answerResponse.value?.trueOptionId === id) {
          optClass = 'disabled true';
        } else if (
          answerResponse.value &&
          answerResponse.value.trueOptionId !== id &&
          id === selectedAnswer.value
        ) {
          optClass = 'disabled false';
        } else {
          optClass = 'disabled';
        }
      }

      return optClass;
    };

    const currentQuestion = ref({});

    const buttonLabel = computed(() => {
      if (!submitedAnswer.value) return BUTTON_LABELS.ANSWER;
      return currentQuestionIndex.value === survey.value?.questions?.length - 1
        ? BUTTON_LABELS.FINISH
        : BUTTON_LABELS.NEXT;
    });

    const getIcon = computed(() => {
      return { cross: ICON_VARIABLES.cross };
    });

    const stepCount = () => {
      const currentStep = currentQuestionIndex.value + 1;
      const totalSteps = survey.value?.questions?.length || 0;
      return `${currentStep}/${totalSteps}`;
    };

    const nextQuestion = () => {
      if (!submitedAnswer.value) {
        submitedAnswer.value = true;
        getRosetteQuizAnswer();
      } else if (currentQuestionIndex.value < survey.value?.questions?.length - 1) {
        currentQuestionIndex.value++;
        currentQuestion.value = survey.value?.questions[currentQuestionIndex.value];
        selectedAnswer.value = null;
        submitedAnswer.value = false;
        answerResponse.value = null;
      } else {
        getRosetteQuizFinish();
      }
    };

    const selectOption = id => {
      selectedAnswer.value = id;
    };

    const closeQuizPopup = () => {
      resetQuizState();
      emit('closeQuizPopup');
    };

    const getRosetteQuiz = async () => {
      if (!props.rosetteInput.quizId) return;
      try {
        const res = await Rosettes.getRosetteQuiz(props.rosetteInput.quizId ?? 0);
        survey.value = res.data.Data.detail;
        currentQuestion.value = survey.value?.questions[currentQuestionIndex.value] ?? [];
        isLoading.value = true;
      } catch (error) {
        console.error('Failed to fetch rosette quiz:', error);
      }
    };

    const getRosetteQuizAnswer = () => {
      const request = {
        SurveyId: survey.value.surveyId,
        QuestionId: currentQuestion.value.questionId,
        OptionId: selectedAnswer.value,
        SurveyTakenId: survey.value.surveyTakenId,
      };

      Rosettes.getRosetteQuizAnswer(request).then(res => {
        answerResponse.value = res.data.Data;
      });
    };

    const getRosetteQuizFinish = () => {
      const request = {
        SurveyId: survey.value.surveyId,
        SurveyTakenId: survey.value.surveyTakenId,
        RosetteInputId: props.rosetteInput.rosetteInputId,
        RosetteType: props.rosetteType,
      };

      Rosettes.getRosetteQuizFinish(request).then(res => {
        emit('surveyTakenStatus', res.data?.Data?.surveyTakenStatus);
        isLoading.value = false;
        triggerRefreshRosettes();
        closeQuizPopup();
      });
    };

    const getRosettesList = () => {
      User.getRosetteInfo().then(res => {
        if (!res?.data?.Data) return;
        store.dispatch('app/setRosettes', res.data.Data);
      });
    };

    const getRosetteRewardStatu = () => {
      User.getRosetteRewardStatu().then(res => {
        if (!res?.data?.Data) return;
        store.dispatch('app/setRosetteRewardStatu', res.data.Data.status);
      });
    };

    const triggerRefreshRosettes = () => {
      getRosettesList();
      getRosetteRewardStatu();
    };

    const resetQuizState = () => {
      currentQuestionIndex.value = 0;
      submitedAnswer.value = false;
      selectedAnswer.value = null;
      answerResponse.value = null;
      isLoading.value = false;
      quizStatusType.value = false;
      survey.value = {};
      currentQuestion.value = {};
    };

    onMounted(() => {
      getRosetteQuiz();
    });

    return {
      survey,
      getIcon,
      stepCount,
      isLoading,
      optionClass,
      buttonLabel,
      selectOption,
      nextQuestion,
      closeQuizPopup,
      quizStatusType,
      submitedAnswer,
      selectedAnswer,
      answerResponse,
      currentQuestion,
      currentQuestionIndex,
      triggerRefreshRosettes,
      resetQuizState,
    };
  },
};
</script>

<style lang="scss" scoped>
.bg-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 998;
}

.container {
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  min-height: 100%;
  z-index: 999;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
}

.popup-close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  width: 15px;
}

.quiz-wrapper {
  padding: 50px;
  margin: 0 auto;
  width: 100%;
  max-height: 90vh;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 5px;
  position: relative;
}
.quiz {
  padding: 10px 0;

  &-img {
    width: 100%;
  }
  .question {
    margin-bottom: 20px;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    span {
      margin-left: 20px;
      margin-right: 5px;
      font-weight: 500;
    }
  }
  .option {
    display: block;
    width: 100%;
    background-color: #fff;
    min-height: 60px;
    border-radius: 4px;
    border: solid 1px #79838e;
    margin-bottom: 10px;
    text-align: left;
    padding-left: 20px;
    font-size: 16px;
    font-weight: 400;
    &.active {
      color: #fff;
      background-color: #79838e;
      border-color: #79838e;
    }
    &.true {
      color: #fff;
      background-color: #6dd400;
      border-color: #6dd400;
    }
    &.false {
      color: #fff;
      background-color: #e5472d;
      border-color: #e5472d;
    }
    &.disabled {
      pointer-events: none;
    }
  }
}
.btn-answer {
  font-weight: 500;
  height: 60px !important;
  font-size: 18px;
  background: #c60000;
}
</style>
